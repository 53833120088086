import React from 'react';
import img from '../images/Web-developer.svg';
import { Link } from 'react-router-dom';

const Intro = ({width}) => {
    return (
        <div className="bg-gradient-to-r from-purple-800 to-pink-800 ">
            <div className="m-auto w-3/4 p-2 md:p-12 h-5/6 flex justify-center items-center" id='about' >

                <div className={`flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left w-full`} data-aos="fade-up">
                    <div className="lg:w-2/5 flex flex-col lg:mx-4 justify-center">
                        <img alt="card img" className="rounded-t float-right" src={img} />
                    </div>
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                        <h3 className="text-3xl text-zinc-100 font-bold">Our Vision</h3>
                        <div>
                            <p className='my-3 text-xl text-zinc-300 font-semibold'>In the current crypto world, the points given to users by projects do not have liquidity and clear profitability, which creates a pain point. Users have to worry about their expected benefits while bearing high time costs and opportunity costs. There is no accuracy or even guarantee that there will be a profit. In such a situation, users who earn points need to find a suitable path to ensure the certainty of their income. For example, you can redeem your rights in advance and convert points into tokens to sell them to lock in profits before TGE. That’s why we built Depoint.</p>
                        </div>

                        {/*<div>*/}
                        {/*    <p className='my-3 text-xl text-zinc-300 font-semibold'>We take responsibility for building custom software solutions that caters for automation of your business processes and improve efficiency.</p>*/}
                        {/*</div>*/}
                        <a href="https://discord.gg/Zm3xxQH4X8" className="text-white bg-emerald-600 hover:bg-emerald-500 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                            Contact us
                            <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intro;
