import React from 'react';
import img from '../images/web.svg';
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';

const Services = ({width}) => {

    return (
      <>
          <ul className="background">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
          </ul>
        <div id="services" className="py-12 flex justify-center items-center" >
            <div className="w-4/5">
                <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-zinc-100 uppercase font-bold">Core Features</h2>

                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        {/*<h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-zinc-100">We are deeply committed to the growth and success of our clients.</h2>*/}
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-5">

                            <div className="bg-zinc-800 transition-all ease-in-out duration-400  overflow-hidden text-gray-300 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm flex flex-col justify-center">
                                    <h2 className="font-semibold my-4 text-2xl text-center text-green-400">Earn Points through Depoint</h2>
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out h-60" src={img} />
                                    <p className="text-md font-medium">
                                        You can regard Depoint as a tool or agent, which will automatically help you earn targeted Web3 Points in a totally decentralized and trustless way. For example, if you want to earn Renzo and Swell Points, just Deposit ETH to Renzo and Swell Pool on Depoint Dapp.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-zinc-800 transition-all ease-in-out duration-400  overflow-hidden text-gray-300 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm flex flex-col justify-center">

                                    <h2 className="font-semibold my-4 text-2xl text-center text-green-400">Turn Points into ERC-20 Tokens</h2>
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out h-60" src={img2} />
                                    <p className="text-md font-medium">
                                        The process of precisely tokenizing the earned points into tradable ERC-20 FT (Ethereum standard tokens) is called Point Tokenization. The points that have been tokenized are referred to as **Point-Tokens**, and users have complete control over their own Point-Tokens, allowing them to freely manage them.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-zinc-800 transition-all ease-in-out duration-400  overflow-hidden text-gray-300 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm flex flex-col justify-center">
                                    <h2 className="font-semibold my-4 text-2xl text-center  text-green-400">One-stop Web3 Points management</h2>
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out h-60" src={img3} />

                                    <p className="text-md font-medium">
                                        Depoint serves as an One-stop Platform for users to manage their projects, including but not limited to,check points in dashboard, tokenization, trade and stake.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-zinc-800 transition-all ease-in-out duration-400  overflow-hidden text-gray-300 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm flex flex-col justify-center">
                                    <h2 className="font-semibold my-4 text-2xl text-center  text-green-400">SubDAO governance function</h2>
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out h-60" src={img4} />

                                    <p className="text-md font-medium">
                                        The governance of the web3 project is inseparable from DAO. There are sufficient cases to prove that subDAO is the best supplement and improvement to DAO governance. The loyal user community of the project party based on the points system is naturally a subDAO. Through Depoint’s subDAO governance, Depoint can influence and promote the project party to be more decentralized and maximize the interests of Point-Token users. Capturing governance can extract value.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="mt-20 py-4">
                        <h2 className="my-2 text-center text-3xl text-zinc-100 uppercase font-bold">How did Depoint maximize Points’ value for users</h2>

                        <div className='flex justify-center'>
                            <div className='w-80 border-b-4 border-blue-900'></div>
                        </div>
                        {/*<h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-zinc-100">We are deeply committed to the growth and success of our clients.</h2>*/}
                    </div>
                    <div className="m-auto w-full p-2 md:p-12 h-5/6">
                        <div className="flex flex-col-reverse items-start lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                            <div className="lg:w-1/3 flex flex-col lg:mx-4 justify-center">
                                <div className='text-zinc-100 mb-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path><path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path></svg>
                                </div>
                                <h3 className="text-3xl  text-zinc-100
                            font-bold">Free Points' Liquidity</h3>
                                <div>
                                    <p className='my-3 text-xl text-gray-300 font-semibold'>
                                        Convert points tokens into tradable ERC-20 tokens, which have better liquidity and allow point users to have greater initiative and free control.                                    </p>
                                </div>
                            </div>
                            <div className="lg:w-1/3 flex flex-col lg:mx-4 justify-center">
                                <div className='text-zinc-100 mb-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path></svg>
                                </div>
                                <h3 className="text-3xl  text-zinc-100 font-bold">Simplify Point Management</h3>
                                <div>
                                    <p className='my-3 text-xl text-gray-300 font-semibold'>Realize future rights and interests at the contract level. Depoint can realize future rights and interests through tokenized points in a completely decentralized and on-chain manner.</p>
                                </div>
                            </div>
                            <div className="lg:w-1/3 flex flex-col lg:mx-4 justify-center">
                                <div className='text-zinc-100 mb-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'>
                                        <path d="M12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,12,10Zm6,13A6,6,0,0,0,6,23a1,1,0,0,0,2,0,4,4,0,0,1,8,0,1,1,0,0,0,2,0ZM18,8a4,4,0,1,1,4-4A4,4,0,0,1,18,8Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,18,2Zm6,13a6.006,6.006,0,0,0-6-6,1,1,0,0,0,0,2,4,4,0,0,1,4,4,1,1,0,0,0,2,0ZM6,8a4,4,0,1,1,4-4A4,4,0,0,1,6,8ZM6,2A2,2,0,1,0,8,4,2,2,0,0,0,6,2ZM2,15a4,4,0,0,1,4-4A1,1,0,0,0,6,9a6.006,6.006,0,0,0-6,6,1,1,0,0,0,2,0Z"/>
                                    </svg>
                                </div>
                                <h3 className="text-3xl  text-zinc-100 font-bold">Point SubDAO</h3>
                                <div>
                                    <p className='my-3 text-xl text-gray-300 font-semibold'>Utilize subDAO governance to influence and promote the project side to be more decentralized and maximize the interests of Point-Token users.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </div>
      </>
    )
}

export default Services;
