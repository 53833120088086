import React from 'react';
import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">

                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-gray-200 lg:ml-11">

                    {/* 1st block */}
                    <div className="col-span-12 lg:col-span-4">
                        <div className="p-8 text-gray-600 text-center rounded-lg xl:w-900 mx-auto">
                            <h3 className="font-bold text-4xl mb-4 text-gray-200">Depoint Protocol</h3>
                        </div>
                    </div>

                    {/* 2nd block */}
                    <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
                        <h6 className="text-zinc-300 text-xl font-bold mb-4">LINKS</h6>
                        <ul className="text-md">
                            <li className="mb-2">
                                <a href="https://docs.depoint.xyz" className="text-zinc-300 hover:text-white transition duration-250 ease-in-out">Docs</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://github.com/depoint-xyz/depoint-protocol" className="text-zinc-300 hover:text-white transition duration-250 ease-in-out">Github</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://docs.depoint.xyz/useful-links/contract-addresses" className="text-zinc-300 hover:text-white transition duration-250 ease-in-out">Contracts</a>
                            </li>
                        </ul>
                    </div>

                    {/* 3rd block */}
                    <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
                        <h6 className="text-zinc-300 text-xl font-bold mb-4">OVERVIEW</h6>
                        <ul className="text-md">
                            <li className="mb-2">
                                <a href="https://docs.depoint.xyz/overview/what-is-depoint-dao" className="text-zinc-300 hover:text-white transition duration-250 ease-in-out">What is Depoint Protocol</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://docs.depoint.xyz/overview/what-is-the-core-features-of-depoint-dao" className="text-zinc-300 hover:text-white transition duration-250 ease-in-out">What is the core features of Depoint Protocol</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://docs.depoint.xyz/overview/why-do-we-need-depoint-dao" className="text-zinc-300 hover:text-white transition duration-250 ease-in-out">Why do we need Depoint Protocol</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://docs.depoint.xyz/overview/how-depoint-tokenizes-web3-point" className="text-zinc-300 hover:text-white transition duration-250 ease-in-out">How Depoint Tokenizes Web3 Point</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://docs.depoint.xyz/overview/why-point-token-and-point-have-the-same-value" className="text-zinc-300 hover:text-white transition duration-250 ease-in-out">Why Point-Token and Point have the same value</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://docs.depoint.xyz/overview/is-it-safe-to-use-depoint" className="text-zinc-300 hover:text-white transition duration-250 ease-in-out">Is it safe to use Depoint?</a>
                            </li>
                        </ul>
                    </div>

                    {/* 4th block */}
                    <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-zinc-300">
                        <div className="text-xl mb-6">
                            JOIN THE COMMUNITY
                        </div>
                        <div className="mx-auto text-center mt-2">
                            <ul className="flex justify-center mb-4 md:mb-0">
                                <li className="ml-4">
                                    <a href="https://twitter.com/depointxyz" className="flex justify-center items-center text-zinc-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Facebook">
                                        <svg className="w-8 h-8 fill-current" xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 512">
                                            <path d="M256 0c141.385 0 256 114.615 256 256S397.385 512 256 512 0 397.385 0 256 114.615 0 256 0z"/><path fill="#fff" fill-rule="nonzero" d="M318.64 157.549h33.401l-72.973 83.407 85.85 113.495h-67.222l-52.647-68.836-60.242 68.836h-33.423l78.052-89.212-82.354-107.69h68.924l47.59 62.917 55.044-62.917zm-11.724 176.908h18.51L205.95 176.493h-19.86l120.826 157.964z"/>
                                        </svg>
                                    </a>
                                </li>
                                <li className="ml-4">
                                    <a href="https://medium.com/@depointxyz" className="flex justify-center items-center text-zinc-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Facebook">
                                        <svg className="w-8 h-8 fill-current" xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 512">
                                            <path d="M256 0c141.385 0 256 114.615 256 256S397.385 512 256 512 0 397.385 0 256 114.615 0 256 0z"/><path fill="#fff" fill-rule="nonzero" d="M274.898 255.999c0 46.265-37.251 83.772-83.202 83.772-45.952 0-83.199-37.496-83.199-83.772 0-46.277 37.25-83.77 83.199-83.77s83.202 37.504 83.202 83.77zm91.273 0c0 43.553-18.626 78.857-41.601 78.857-22.974 0-41.601-35.316-41.601-78.857 0-43.542 18.627-78.857 41.601-78.857 22.975 0 41.601 35.315 41.601 78.857zm37.332 0c0 39.021-6.551 70.652-14.631 70.652s-14.632-31.642-14.632-70.652c0-39.01 6.552-70.653 14.634-70.653 8.083 0 14.629 31.634 14.629 70.653z"/>
                                        </svg>
                                    </a>
                                </li>
                                <li className="ml-4">
                                    <a href="https://discord.gg/Zm3xxQH4X8" className="flex justify-center items-center text-zinc-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Facebook">
                                        <svg className="w-8 h-8 fill-current" xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 512">
                                            <path d="M256 0c141.385 0 256 114.615 256 256S397.385 512 256 512 0 397.385 0 256 114.615 0 256 0zm104.932 160.621a250.428 250.428 0 00-62.383-19.182 173.883 173.883 0 00-7.966 16.243 232.557 232.557 0 00-34.619-2.603c-11.569 0-23.195.879-34.622 2.58-2.334-5.509-5.044-10.971-7.986-16.223a252.58 252.58 0 00-62.397 19.222c-39.483 58.408-50.183 115.357-44.833 171.497a251.49 251.49 0 0076.502 38.398c6.169-8.327 11.695-17.192 16.386-26.417a161.682 161.682 0 01-25.813-12.319c2.164-1.569 4.281-3.186 6.325-4.756 23.912 11.231 50.039 17.088 76.473 17.088 26.436 0 52.562-5.857 76.475-17.089 2.069 1.688 4.186 3.305 6.325 4.755a162.693 162.693 0 01-25.86 12.352 183.969 183.969 0 0016.387 26.397 250.498 250.498 0 0076.553-38.392l-.006.007c6.277-65.104-10.725-121.53-44.941-171.558zM205.78 297.63c-14.908 0-27.226-13.53-27.226-30.175 0-16.645 11.889-30.293 27.178-30.293 15.29 0 27.511 13.648 27.25 30.293-.262 16.645-12.008 30.175-27.202 30.175zm100.439 0c-14.933 0-27.202-13.53-27.202-30.175 0-16.645 11.889-30.293 27.202-30.293 15.313 0 27.44 13.648 27.178 30.293-.261 16.645-11.984 30.175-27.178 30.175z"/>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                    <div className="text-sm text-gray-200 font-semibold py-1">
                    Copyright &copy; {new Date().getFullYear()}{"  "}
                    <HashLink
                        to="#"
                        className=" hover:text-gray-900"
                    >
                        Depoint Protocol
                    </HashLink>. All rights reserved.
                    </div>
                </div>
                </div>

                </div>

            </footer>
        </>
    )
}
export default Footer;
