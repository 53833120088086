import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import NavBar from "../components/Navbar/NavBar";
import Waves from "../components/Waves";
import Marquee from "react-fast-marquee";


const Home = () => {
  const width = 'w-2/3'
  return (
    <div >
      <NavBar />
      <Hero width={width} />
      <Intro />
      <Services width={width}/>
      {/*<Portfolio width={width}/>*/}
      <Clients />
      <Cta/>
      <Footer />
    </div>
  )
}

export default Home;

