/*
 * @Author: depointhomepage web3mario@proton.me
 * @Date: 2024-02-26 11:22:25
 * @LastEditors: depointhomepage web3mario@proton.me
 * @LastEditTime: 2024-04-15 18:10:40
 * @FilePath: /depoint-homepage-frontend/src/components/Navbar/NavLinks.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <a className="px-4 font-extrabold text-purple-500 hover:text-purple-200 text-xl" href="https://linktr.ee/depoint">
                LinkTree
            </a>
            <a className="px-4 font-extrabold text-gray-200 hover:text-white text-xl" href="https://docs.depoint.xyz">
                DOC
            </a>
            <a className="px-4 font-extrabold text-gray-200 hover:text-white text-xl" href="https://medium.com/@depointxyz">
                Blog
            </a>
            <a className="px-4 font-extrabold text-gray-200 hover:text-white text-xl" href="https://twitter.com/depointxyz">
                X
            </a>
            <a className="px-4 font-extrabold text-gray-200 hover:text-white text-xl" href="https://discord.gg/Zm3xxQH4X8">
                Discord
            </a>
            <a className="text-white bg-emerald-600 hover:bg-emerald-500 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" href="https://app.depoint.xyz">
                Launch APP
            </a>
        </>
    )
}

export default NavLinks;
