import React from 'react';
import kws from '../images/clients/kws.png';
import geps from '../images/clients/geps.png';
import protergia from '../images/clients/protergia.png';
import Marquee from "react-fast-marquee";

import etherFiLogo from '../images/protocols/EtherFi.png';
import kelpLogo from '../images/protocols/KelpDAO.png';
import renzoLogo from '../images/protocols/Renzo.png';
import swellLogo from '../images/protocols/Swell.png';

const supportedProtocol = [
  {
    name: 'EtherFi',
    logo: etherFiLogo
  },
  {
    name: 'KelpDAO',
    logo: kelpLogo
  },
  {
    name: 'Renzo',
    logo: renzoLogo
  },
  {
    name: 'Swell',
    logo: swellLogo
  },
]

const clientImage = {
  height: '5rem',
  width: 'auto',
  mixBlendMode: 'colorBurn'
}

const Clients = () => {
  return (
    <div className="mt-8 bg-gradient-to-r from-purple-800 to-pink-800 w-full">
      <section data-aos="fade-up">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-zinc-200 uppercase font-bold">Our Partners</h2>
          <div className='flex justify-center'>
              <div className='w-24 border-b-4 border-blue-900'></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-zinc-200">Some of our supported protocols.</h2>
        </div>
        <div className="p-16" data-aos="fade-in" data-aos-delay="600">
          <Marquee autoFill>


            <div style={clientImage} className="p-16 overflow-hidden p-3 flex justify-center items-center transition-all ease-in-out opacity-50 hover:opacity-100
              text-zinc-100 hover:text-white
            ">
              <img src={etherFiLogo} alt="etherFi" className="h-16 p-2" />
              <div className="text-3xl">
                EtherFi
              </div>
            </div>
            <div style={clientImage} className="p-16 overflow-hidden p-3 flex justify-center items-center transition-all ease-in-out opacity-50 hover:opacity-100
              text-zinc-100 hover:text-white
            ">
              <img src={kelpLogo} alt="KelpDAO" className="h-16 p-2" />
              <div className="text-3xl">
                KelpDAO
              </div>
            </div>
            <div style={clientImage} className="p-16 overflow-hidden p-3 flex justify-center items-center transition-all ease-in-out opacity-50 hover:opacity-100
              text-zinc-100 hover:text-white
            ">
              <img src={renzoLogo} alt="Renzo" className="h-16 p-2"/>
              <div className="text-3xl">
                Renzo
              </div>
            </div>
            <div style={clientImage} className="p-16 overflow-hidden p-3 flex justify-center items-center transition-all ease-in-out opacity-50 hover:opacity-100
              text-zinc-100 hover:text-white
            ">
              <img src={swellLogo} alt="Swell" className="h-16 p-2"/>
              <div className="text-3xl">
                Swell
              </div>
            </div>
          </Marquee>
        </div>
      </section>
    </div>
  )
}

export default Clients;
